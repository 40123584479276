.Ofert_Page {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 30px;
  position: relative;
  transition: backdrop-filter 0.7s ease;
  .title_page {
    color: black !important;
  }
  .Ofert_Page__content {
    display: flex;
    flex-direction: column;
    color: white;
    height: 80%;
    justify-content: center;
    .content_multi-container {
      display: flex;
      height: 50%;
      width: 100%;
      .multi-container--infoBox {
        padding: 20px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        text-align: center;
        .ofert_title {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .Ofert_Page {
    min-height: 70vh;
    align-items: flex-start;
    .Ofert_Page__content {
      width: 100%;
      height: 100%;
      padding: 20px;
      .content_multi-container {
        flex-direction: column;
        .multi-container--infoBox {
          margin: 20px 0;
          .ofert_title {
            align-items: flex-start;
            img {
              filter: invert(99%) sepia(43%) saturate(2%) hue-rotate(203deg)
                brightness(112%) contrast(101%);
            }
            h1 {
              font-size: 30px;
              margin: 10px 0 20px 20px;
              text-align: left;
              text-transform: uppercase;
            }
          }

          .infoBox_list {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            .infoBox_list--item {
              font-size: 18px;
              padding: 10px 5px;
              margin: 5px 0;
              display: flex;
              justify-content: center;
              border-bottom: 2px solid white;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 700px) {
  .Ofert_Page {
    min-height: 100vh;
    width: calc(100% - 80px);
    .Ofert_Page__content {
      margin: 10px auto;
      height: 80%;
      width: 90%;
      .content_multi-container {
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 10px;
        width: 100%;
        div:nth-child(2) {
          margin-top: 50px;
          animation-delay: 0.5s;
        }
        .multi-container--infoBox {
          width: 100%;
          max-height: 50%;
          background-color: transparent;

          .ofert_title {
            flex-direction: column;
            margin: 10px 0;
            padding: 10px;
            img {
              width: 55px;
              height: 55px;
              margin: 10px 0;
            }
            h1 {
              font-size: 34px;
              text-transform: uppercase;
              color: black;
              letter-spacing: 2px;
              font-weight: 600;
            }
          }
          hr {
            border-radius: 25px;
            background-color: orange;
            width: 50%;
            height: 5px;
            margin: auto;
            border: 0;
          }
          .infoBox_list {
            margin: 10px 0;
            padding: 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            .infoBox_list--item {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: clamp(16px, 1.6vw, 22px);
              margin: 10px;
              padding: 10px;
              width: calc(20% - 20px);
              height: 10vw;
              background-color: white;
              color: black;
              box-shadow: rgba(3, 106, 65, 0.8) 1px 1px 5px 2px;
              list-style: none;
            }
          }
        }
      }
    }
  }
}
