.btnBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  .btn_send {
    height: 3rem;
    margin-top: 1.2rem;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: orange;
    border: 0;
    border-radius: 10px;
    &:first-child {
      margin-right: 1%;
      width: 49%;
    }
    &:nth-child(2) {
      width: 50%;
    }
    &:nth-child(3) {
      width: 100%;
    }
    &:hover {
      transform: scale(1.02);
      cursor: pointer;
    }
  }
}
