$filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
  brightness(104%) contrast(97%);
$color_1: #000;
$background-color_1: black;
$background-color_2: white;
$filter-black: brightness(0) saturate(100%);

.ResponsiveMenu {
  position: fixed;
  transition: all 0.2s ease;
  z-index: 100;
  #menu-toggle {
    display: none;
  }

  #menu-toggle:checked ~ .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 100px);
    background-color: red;
  }
}

@media (min-width: 700px) {
  .arrowUp {
    display: none;
  }
  .ResponsiveMenu {
    top: 0;
    right: 0;
    width: 80px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    &:hover {
      width: 180px;
      .imgBox {
        img {
          transition: 0.4s ease-in-out;
          height: 100% !important;
          border-bottom: 2px solid white;
        }
      }
      img {
        margin-left: 0 !important;
      }
      span {
        margin-left: 20px !important;
      }
    }
    .active {
      img {
        filter: $filter !important;
      }
    }
    .menu-content {
      display: flex;
      flex-direction: column;
      height: 60%;
      .imgBox {
        height: 20%;
        display: block;
        padding: 5px;

        img {
          height: 70%;
          width: 100%;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        height: 80%;
        justify-content: flex-start;

        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          height: calc(100% / 6);
          font-size: 0.7rem;
          padding: 2px 10px 5px 10px;
          margin: 5px 0;
          position: relative;
          overflow: hidden;
          width: 100%;
          transition: all 0.2s ease-in-out;

          a.active {
            .list-img {
              transition: all 0.5s ease-in-out;
              transform: scale(0.9);
            }
            &::before {
              transition: all 0.2s ease-in-out;
              content: "";
              position: absolute;
              background-color: rgba(255, 255, 255, 0.141);
              top: -5px;
              height: calc(100% + 10px);
              width: 250px;
              transform: translateX(-50px);
              color: white !important;
            }
          }
          a.active {
            color: orange;
            span {
              font-size: 0.85rem;
            }
          }

          a {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            color: white;
            margin-top: 1px;
            text-decoration: none;
            height: 100%;
            z-index: 1;
            &::before {
              content: "";
              position: absolute;
              background-color: rgba(255, 255, 255, 0.141);
              top: 0;
              height: calc(100% + 10px);
              width: 65px;
              transform: translateX(-75px);
              transition: all 0.2s ease-in-out;
            }
            &:hover::before {
              transform: translateX(-10px);
            }
            &:hover {
              color: orange;
            }
            img {
              margin-left: 12.5px;
              width: 40px;
              height: 40px;
            }
            span {
              margin-left: 35px;
              text-transform: uppercase;
              letter-spacing: 2px;
              font-size: 0.8rem;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .ResponsiveMenu {
    bottom: 90px;
    right: 10px;
    width: 70px;
    height: 70px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 0 0 #fff, 0 0 0 0 #fff;
    cursor: pointer;
    border-radius: 50%;
    .menuToggle {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-box-shadow: 0px 8px 23px -12px rgba(0, 0, 0, 1);
      -moz-box-shadow: 0px 8px 23px -12px rgba(0, 0, 0, 1);
      box-shadow: 0px 8px 23px -12px rgba(0, 0, 0, 1);
      border-radius: 50%;
    }

    #menu-toggle:checked ~ .menuToggle {
      position: absolute;
      z-index: 102;
      box-shadow: none;
      top: 0;
      left: 0;
    }
    #menu-toggle:checked ~ .menuToggle .navicon:before {
      transform: rotate(-45deg);
    }
    #menu-toggle:checked ~ .menuToggle .navicon {
      background: transparent;
    }
    #menu-toggle:checked ~ .menuToggle .navicon:after {
      transform: rotate(45deg);
    }

    #menu-toggle:checked ~ .menuToggle:not(.steps) .navicon:before,
    #menu-toggle:checked ~ .menuToggle:not(.steps) .navicon:after {
      top: 0;
    }
    .menu {
      display: none;
    }
    .navicon {
      background: #333;
      display: block;
      height: 4px;
      position: relative;
      transition: background 0.2s ease-out;
      width: 36px;
      &::before,
      &::after {
        background: #333;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
      }
      &::before {
        top: 10px;
      }
      &::after {
        top: -10px;
      }
    }
    .menu-content {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      transition: 0.35s 0s ease-out;
      opacity: 0;
      width: 70px;
      height: 70px;
      bottom: 90px;
      right: 10px;
      z-index: -1;
      background-color: white;
      .imgBox {
        display: none;
      }
    }
    #menu-toggle:checked ~ .menu-content {
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: 0.3s ease;
      .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        li {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 10px;
          font-size: 30px;
          a {
            display: flex;
            flex-direction: row;
            margin-top: 5px;
            text-decoration: none;
            color: black;
            justify-content: center;
            align-items: center;
            img {
              width: 60px;
              height: 60px;
              filter: $filter-black;
              margin-right: 10px;
            }
            span {
              font-size: 1.6rem;
              text-transform: uppercase;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
    .arrowUp {
      position: fixed;
      bottom: 10px;
      right: 10px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0px 8px 23px -12px rgba(0, 0, 0, 1);
      button {
        border: none;
        img {
          width: 100%;
          height: 100%;
          background-color: transparent;
          display: block;
        }
      }
    }
  }
}
