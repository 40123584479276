.WhyThisPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0 50px 0;
  transition: backdrop-filter 0.7s ease;
  .whyThisListItem {
    width: 55px;
    height: 55px;
    margin: 10px 0;
  }
  h1 {
    color: black;
  }
  .WhyThis__content {
    width: 95%;
    margin: 0 auto;
    .whyUs--item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      backdrop-filter: blur(7px);
      border-radius: 25px;
      overflow: hidden;
      margin-bottom: 20px;

      .title {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 34px;
        padding: 10px;
        margin: 10px 0;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        span {
          margin-top: 10px;
          font-size: 0.8rem;
          color: rgba(0, 0, 0, 0.7);
          letter-spacing: 1.5px;
          text-transform: none;
        }
      }
      hr {
        border-radius: 25px;
        background-color: orange;
        width: 50%;
        height: 5px;
        margin: auto;
        border: 0;
      }
      .item__content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
        padding: 10px;
        .content--item {
          width: clamp(200px, calc((100% / 3) - 10px), 450px);
          min-height: 300px;
          border-radius: 25px;
          margin: 10px 5px;
          padding: 20px;
          box-shadow: rgba(3, 106, 65, 0.8) 1px 1px 5px 2px;
          text-align: center;
          background-color: rgba(255, 255, 255, 1);
          h2 {
            margin-bottom: 20px;
            text-transform: uppercase;
            &::after {
              display: inline-block;
              content: "";
              width: 90%;
              height: 2px;
              margin: 0 auto;
              background-color: rgba(3, 106, 65, 0.1);
            }
          }
          p {
            font-size: 1.1rem;
            letter-spacing: 0.5px;
          }
        }
      }
      @media (max-width: 700px) {
        .title {
          p,
          span {
            text-align: center;
          }
          span {
            font-size: 14px;
          }
        }
        .item__content {
          flex-wrap: nowrap;
          overflow-x: scroll;
          position: relative;
          justify-content: flex-start;

          .content--item {
            display: inline-block;
            min-width: 93%;
            padding: 10px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 700px) {
  .WhyThisPage {
    width: calc(100% - 80px);
  }
  .content--item {
    h2 {
      font-size: clamp(16px, 1.6vw, 21px);
    }
  }
}

@media (max-width: 700px) {
  .WhyThisPage {
    .title_page {
      margin-bottom: 20px;
    }
  }
}
