.galleryPage {
  width: 100%;
  display: flex;
  padding-top: 30px;
  padding-bottom: 50px;
  transition: backdrop-filter 0.7s ease;
  align-items: center;
  h1 {
    color: black;
  }
}

@media only screen and (min-width: 700px) {
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  .galleryPage {
    width: calc(100% - 80px);
    min-height: 100vh;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

@media only screen and (max-width: 700px) {
  .galleryPage {
    min-height: 100vh;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    padding: 30px 0;
  }
}

.GalleryPicOnPage_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

@media only screen and (min-width: 700px) {
  .GalleryPicOnPage_box {
    width: 100%;
    margin: 20px;
  }
}
@media only screen and (max-width: 700px) {
  .galleryPage {
    min-height: min-content !important;
  }
  .GalleryPicOnPage_box {
    text-align: center;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 100%;
  }
}
