.GalleryPicOnPage_content {
  margin: 10px;
  .GalleryPicOnPage_content--img {
    border-radius: 50%;
  }
}

@media only screen and (min-width: 700px) {
  .GalleryPicOnPage_content {
    margin: 0;
    display: block;
    width: 200px;
    min-height: 200px;
    border-radius: 1rem;
    transition: transform 0.3s ease;
    padding: 5px;

    .GalleryPicOnPage_content--img {
      width: 100%;
      height: 100%;
      border-radius: 25px;
      box-shadow: rgb(0 0 0 / 52%) 1px 1px 5px 2px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .GalleryPicOnPage_content {
    max-width: 80%;

    .GalleryPicOnPage_content--img {
      width: 150px;
      height: 150px;
      border-radius: 10%;
      box-shadow: rgb(0 0 0 / 52%) 1px 1px 5px 2px;
      &:focus-visible {
        opacity: 0;
      }
    }
  }
}
