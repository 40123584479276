.edit_opinion_box {
  form {
    h2 {
      width: 100%;
      text-align: center;

      letter-spacing: 1px;
      font-size: 1.4rem;
    }
  }
}

.updatePop_box {
  width: 100vw;
  display: flex;
  height: auto;
  justify-content: center;
  font-family: "Roboto";
  position: fixed;
  top: 50px;
  left: 0;
  align-items: center;
  z-index: 1008;
  .updatepop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 6px solid orange;
    border-radius: 40px;
    padding: 10px;
    background-color: white;
    height: auto;
    z-index: 1002;
    text-align: center;
  }
}

@media (min-width: 700px) {
  .updatepop {
    width: 30%;
  }
}
@media (max-width: 700px) {
  .updatepop {
    width: 95%;
  }
}
