.contactPage {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  transition: backdrop-filter 0.7s ease;

  h1 {
    color: black;
  }
  .contactPage__content {
    display: flex;
  }
}

@media (min-width: 700px) {
  .contactPage {
    min-height: 100vh;
    width: calc(100% - 80px);
    .contactPage__content {
      flex-direction: row;
      flex-wrap: wrap;
      width: 80%;
      height: auto;
      margin: 0 auto;
      align-items: center;
    }
  }
}

@media (max-width: 700px) {
  .contactPage {
    overflow: hidden;
    .contactPage__content {
      min-height: 85vh;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
