div.omrs-input-group {
  margin-bottom: 1.2rem;
  padding: 0;
  position: relative;
  width: 100%;
  .omrs-input-underlined {
    &:not(.omrs-input-danger) > input:focus + .omrs-input-label {
      color: #1e4bd1;
    }

    &:not(.omrs-input-danger) > input:focus {
      border-color: #1e4bd1;
    }

    input {
      border: none;
      border-bottom: 0.125rem solid rgba(19, 19, 21, 0.6);
      width: 100%;
      height: 2rem;
      font-size: 1.0625rem;
      padding-left: 0.875rem;
      line-height: 147.6%;
      padding-top: 0.825rem;
      padding-bottom: 0.5rem;
      &:focus {
        outline: none;
      }
      &:hover {
        background: rgba(73, 133, 224, 0.12);
        border-color: #121212;
      }
      &:focus + .omrs-input-label,
      &:valid + .omrs-input-label {
        top: -12px;
        font-size: 1.2rem;
        margin-bottom: 32px;
      }
    }

    .omrs-input-label {
      position: absolute;
      top: 0;
      left: 5px;
      color: rgba(19, 19, 21, 0.6);
      transition: top 0.2s;
    }

    .omrs-input-helper {
      font-size: 0.8rem;
      color: rgba(19, 19, 21, 0.6);
      letter-spacing: 0.0275rem;
      margin: 0.125rem 0.875rem;
    }
  }
}
