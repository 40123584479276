.content_info {
  display: flex;
  align-items: center;
  width: 80%;
  backdrop-filter: blur(30px);
  height: 30%;
  margin: 5px;
  padding: 20px;
  font-size: 20px;
  letter-spacing: 1px;
  box-shadow: rgb(0 0 0 / 52%) 1px 1px 5px 2px;
  text-align: left;
  font-weight: 600;
  transform: translateX(50vw);
  animation: animationTest 0.8s ease forwards;
  color: white;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.7);
  &:nth-child(1) {
    animation-delay: 0.7s;
  }
  &:nth-child(2) {
    animation-delay: 0.6s;
  }
  &:nth-child(3) {
    animation-delay: 0.5s;
  }
}

@keyframes animationTest {
  from {
    transform: translateX(50vw);
  }
  to {
    transform: translateX(0);
  }
}

@media (max-width: 700px) {
  .content_info {
    width: 90%;
    animation: animationMobile 0.8s ease forwards;
    transform: translateX(100vw);
  }
}

@keyframes animationMobile {
  from {
    transform: translateX(100vw);
    display: none;
  }
  to {
    transform: translateX(0);
    display: flex;
  }
}
