* {
  font-family: "Asap", sans-serif;
}
@import "./styles/animate.scss";

body {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  background-image: url("assets/bg_gallery.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease, transform 500ms ease;
}
.page-exit {
  opacity: 1;
  transform: translateX(0);
}
.page-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 500ms ease, transform 500ms ease;
}

@media (max-width: 700px) {
  body {
    font-size: 14px;
    background-size: auto;
  }
}

$titlePagePadding: 10px;
$titlePageMargin: 0 0 30px 0;

.title_page {
  padding: $titlePagePadding;
  margin: $titlePageMargin;
  text-align: center;
  width: 35%;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  transition: all 1s ease-in-out;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.6) 28%,
    rgba(41, 53, 86, 0) 98%
  );
  animation: openTitle 0.6s ease-in forwards;
}

@keyframes openTitle {
  0% {
    width: 35%;
  }
  100% {
    width: 100vw;
    text-align: center;
    letter-spacing: 10px;
  }
}

@media (min-width: 700px) {
  .title_page {
    min-width: 400px;
    height: fit-content;
  }
}

@media (max-width: 700px) {
  .title_page {
    width: 100vw;
    margin: 0;
    font-size: 2.25rem;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(41, 53, 86, 0) 94%
    );
  }
}

.openModalBg {
  will-change: auto;
  transform: scale(1);
  background: rgba(0, 0, 0, 0.7);
}
.openModalOpinion {
  animation: openModal 0.5s forwards;
}

@keyframes openModal {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(0.95);
  }
  95% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
