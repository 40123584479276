@media (min-width: 700px) {
  #map {
    padding: 10px;
    margin: 0 0 0 0;
    display: flex;
    align-items: center;
    width: 90%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 700px) {
  #map {
    height: 50vh;
    width: 100%;
    min-height: 400px;
    margin: 0 10px;
    iframe {
      margin-top: 20%;
      width: 100%;
      height: 80%;
    }
  }
}
