$paddingTopBot: 10px;

.opinionBox {
  display: flex;
  flex-direction: column;
  border: 6px solid orange;
  border-radius: 40px;
  padding: calc($paddingTopBot * 2);
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.5);
  background-color: white;
  .btn_Accept {
    width: 100%;
    height: 3rem;
    margin-top: 1.2rem;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: orange;
    border: 0;
    border-radius: 10px;
    &:first-child {
      margin-right: 1%;
    }
    &:hover {
      transform: scale(1.02);
      cursor: pointer;
    }
  }
}

@media (max-width: 700px) {
  .opinionBox {
    min-width: 80%;
    min-height: 300px;
    height: 100%;
    display: inline-block;
  }
}

@media (min-width: 700px) {
  .opinionBox {
    width: 250px;
    height: 300px;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 1250px) and (min-width: 940px) {
  .opinionBox {
    min-width: 250px;
  }
}
