.add_opinion_box {
  .form-files__single-value {
    width: 60px;
    height: 60px;
    img {
      width: 100px;
      height: 100%;
      padding: 2px;
    }
  }
  .select-form-files {
    padding: 5px 20px;
    width: 90%;
    position: relative;
  }
  .form-files__menu {
    width: 90%;
    padding: 5px 20px;
  }
  .form-files__menu-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 10px;
    max-height: 200px;
  }
  .form-files__option {
    width: 90%;
    width: calc((100% - 10px) / 2.5);
    height: calc((100% - 10px) / 2.5);
    padding: 2px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  form {
    h2 {
      width: 100%;
      text-align: center;

      letter-spacing: 1px;
      font-size: 1.4rem;
    }

    .starsBox {
      padding: 5px 0;
      border-bottom: 3px orange solid;
      width: 100%;
      text-align: center;
      img {
        margin: 0 2px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
