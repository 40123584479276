.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 50%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  background-color: transparent;
}

.mySwiper2 {
  height: 80%;
  width: 60%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 700px) {
  .mySwiper2 {
    width: 90%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
  .close {
    left: 35px;
  }
}

@media (min-width: 700px) {
  .close {
    right: 135px;
  }
}

.close {
  position: absolute;
  top: 15px;

  color: white;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
