$paddingTopBot: 10px;

.opinionsPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  height: 400px;
  h1 {
    color: black;
  }
  .opinionsBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100%;
    padding: 20px;
    .opinion {
      margin: 8px;
      h2 {
        border-top: 4px solid orange;
        border-bottom: 3px orange solid;
        width: 100%;
        text-align: center;
        padding: $paddingTopBot 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        color: rgba(0, 0, 0, 0.7);
        letter-spacing: 1px;
      }
      p {
        padding: $paddingTopBot;
        color: rgba(0, 0, 0, 0.55);
        border-bottom: 3px orange solid;
      }

      .projectLink {
        padding: $paddingTopBot 0;
        border-bottom: 4px orange solid;
        width: 100%;
        text-align: center;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        &:hover {
          cursor: pointer;
        }
      }
      .datePublic {
        text-align: center;
        padding: $paddingTopBot 0;
        border-bottom: 4px orange solid;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        flex-direction: column;
        font-weight: bolder;
      }
    }
  }
}

@media (max-width: 700px) {
  .opinionsPage {
    width: 90%;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    margin-bottom: 50px;
    .opinionsBox {
      overflow-x: scroll;
      flex-direction: row;
      justify-content: flex-start;
      position: relative;
      .opinion:last-child {
        text-align: center;
      }
      .opinion {
        min-width: 80%;
        display: inline-block;
        margin: 0 8px;
      }
    }
  }
}

@media (min-width: 700px) {
  .opinionsPage {
    align-items: flex-start;
    width: 100%;
    .opinionsBox {
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      width: 95%;
    }
  }
}

@media (min-width: 1250px) {
  .opinionsBox {
    flex-wrap: wrap;
  }
}

@media (max-width: 940px) {
  .opinionsBox {
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 90vw;
    .opinion {
      min-width: 250px;
    }
  }
}
@media (max-width: 1250px) and (min-width: 940px) {
  .opinionsBox {
    flex-wrap: none;
    overflow-x: scroll;
    width: 90vw;
    .opinion {
      min-width: 250px;
    }
  }
}

//this same styles

.opinion_form_content {
  padding: 10px !important;
  height: auto;
  z-index: 1002;
  @media (min-width: 700px) {
    width: 30% !important;
  }
}

.opinion_form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
